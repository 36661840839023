import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col } from 'react-bootstrap';
import './Holidays.css';
import { getStaticData } from '../../CalenderFunction/calenderData';
import Holidaydata from '../../CalenderFunction/holiday';

const Holidays = () => {
  // State declarations
  const [calenderType, setCalenderType] = useState([]);
  const [calenderLanguage, setCalenderLanguage] = useState([]);
  const [calenderTypeId, setCalenderTypeId] = useState(1);
  const [calenderLanguageId, setCalenderLanguageId] = useState(1);
  const [holidays, setHolidays] = useState([]);
  const [title, setTitle] = useState('');

  // Fetch holiday data 
  useEffect(() => {
    const fetchHolidayData = async () => {
      const data = await Holidaydata();
      // console.log("fetchHolidayData", data);
      setHolidays(data.holidays);
      setTitle(data.title);
    };

    fetchHolidayData();
  }, []);

  return (
    <Container fluid>
      <Row className="holidayheightset">
        <Col className='p-0'>
          <div className='holidaysbgcolorset'>
            <div className='holidaytitleflex'>
              <div className='holidays'>{title}</div>
            </div>
            <Table hover responsive className="holiday-table">
              <thead className='theadholiday'>
                <tr>
                  <th>Date</th>
                  <th>Day</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {holidays.map((holiday, index) => (
                  <tr key={index}>
                    <td className='holidaydatecolor'>{holiday.date}</td>
                    <td className='holidaydaycolor'>{holiday.day}</td>
                    <td className='holidaynamecolor'>{holiday.name}</td>
                    <td className='holidaydaycolor'>{holiday.types}</td>
                    <td className='holidaydaycolor '>{holiday.comments}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Holidays;
