// AppRoutes.jsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Calender from './Components/Calender/Calender';
import Holidays from './Components/Holidays/Holidays';
import MyanmarCalendar from './Components/Calender/MyanmarCalender';
import MyanmarZodiacSigns from './Components/MyanmarZodiacSigns/MyanmarZodiacSigns';

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Calender />} />
      <Route exact path="/myanmarcalender" element={<MyanmarCalendar />} />
      <Route exact path="/holiday" element={<Holidays />} />
      <Route exact path="/myanmar-zodiac-signs" element={<MyanmarZodiacSigns />} />
    </Routes>
  );
}

export default AppRoutes;
